.editor {
  width: 100%;
  outline: none;
  min-height: 15em;
  overflow-y: scroll;
  border: 1px solid #dae1e7;
  padding: 0 10px;
}

.editor .ql-container {
  background: transparent;
  border: none;
}

.editor .ql-toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  background: red;
  z-index: 1;
}

.editor .ql-snow.ql-toolbar {
  display: block;
  background: transparent;
  border: none;
}

.editor .ql-container.ql-snow {
  border: none;
}

.editor .ql-bubble .ql-editor {
  border: none;
}

.editor .ql-editor {
  min-height: 118em;
  border: none;
  max-height: 360px;
  overflow-y: auto;
}

.editor .toolbar-container {
  position: relative;
}

.toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* tiptap editor */
.ProseMirror {
  outline: none;
  margin: 20px 0;
  min-height: 10em;
}

.editor-container {
  margin: 15px 15px;
}

.editor-container .editor-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}

.editor-container .editor-menu button {
  border-radius: 3px;
}

.editor-container .editor-menu svg {
  cursor: pointer;
  font-size: 17px;
}

.editor-container .editor-menu .heading {
  font-weight: 800;
}

.is-active {
  background-color: #eee;
}

.heading {
  cursor: pointer;
}

p.is-editor-empty:first-child::before {
  color: #b6bec5;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.overflow-btn {
  background: transparent;
}

.overflow-btn:hover {
  background: gray;
  color: green;
}

blockquote {
  border-left: 3px solid rgb(216, 216, 216);
  margin: 1.5rem 0;
  padding-left: 1rem;
}

img {
  display: inline;
  height: auto;
  object-fit: contain;
  margin: 1.5rem 0;
  max-width: 100%;
  border-radius: 10px;
}

img.ProseMirror-selectednode {
  outline: 3px solid var(--purple);
}
